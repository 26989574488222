import 'bootstrap';
import 'bootstrap-select';

import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-rowgroup';
import 'datatables.net-rowgroup-bs4';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons-bs4';
import 'datatables.net-scroller';

import 'jstree';

import 'jquery-validation';
import 'jquery-validation-unobtrusive';
//import 'jquery.easing';

import 'reflect-metadata'; //used by class-transformer to facilitate json-to-object serialization

//import 'select2';
//import 'bootstrap-select';

//import 'leaflet';
//import 'leaflet-svg-shape-markers/dist/leaflet-svg-shape-markers.min.js';
//import 'leaflet.vectorgrid/dist/Leaflet.VectorGrid.bundled.js';
//import 'leaflet-extra-markers';

//import 'intro.js/minified/intro.min.js';

//import 'tiny-slider/dist/tiny-slider';
import { WOW } from 'wowjs/dist/wow';

//import { cloneDeep } from 'lodash';

// EXPORTS

import { DocumentTreeLoader } from './components/document-tree/document-tree-loader'; export { DocumentTreeLoader };
import { DocumentLibraryLoader } from './components/document-library/document-library-loader'; export { DocumentLibraryLoader };

//import { StudyDetailPage } from './network-structure/studies/components/study-detail/study-detail.page'; export { StudyDetailPage };
//import { NewsPage } from './pages/news/news.page'; export { NewsPage };

// SCRIPTS

import './scripts/font-awesome';
import './scripts/date-functions';
//import './scripts/number-functions';
//import './scripts/table-functions';
import './components/scroll-to-top/scroll-to-top';



// STYLES
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'et-line/style.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css';
import 'highcharts/css/highcharts.scss';
import 'jstree/dist/themes/default/style.min.css';
//import 'select2/dist/css/select2.min.css';
//import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';
//import 'bootstrap-select/dist/css/bootstrap-select.min.css';
//import 'leaflet/dist/leaflet.css';
////import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';
//import 'intro.js/minified/introjs.min.css';
//import 'tiny-slider/dist/tiny-slider.css';
import 'animate.css/source/animate.css';

import './styles/_base.scss';
import './styles/variables.scss';
import './styles/shared.scss';

import './styles/elements/nav-bar.scss';
//import './styles/elements/footer.scss';
import './styles/elements/banner-wrapper.scss';
import './styles/elements/button.scss';
import './styles/elements/headings.scss';
import './styles/elements/data-table.scss';

import './components/scroll-to-top/scroll-to-top.scss';
import './components/document-tree/document-tree.scss';
import './components/document-library/document-library.scss';

//import './pages/news/news.scss';

//import './network-structure/studies/components/study-detail/study-detail.scss';


// AUTH MODULE -- imported here to avoid having a separate auth.* bundle
//import { UserManagementPage } from '../auth/pages/management/scripts/management.page';
//export { UserManagementPage }; //to make discoverable as lib export

import '../auth/pages/auth.scss';
import '../auth/components/user-header/user-header.scss';
import '../auth/pages/profile/profile.scss';
//import '../auth/pages/management/management.scss';

