
export class DocumentTree {

    constructor(nodes: any, treeId: string = "jstree", searchBoxId: string = "docSearch", searchClearId: string = "searchClear") {

        //console.log(nodes);
        treeId = '#' + treeId;
        searchBoxId = '#' + searchBoxId;
        searchClearId = '#' + searchClearId;

        $(searchBoxId).val('');
        $(treeId).jstree({
            "plugins": ["search", "wholerow"],
            "search": {
                "show_only_matches": true,
                "fuzzy": false
            },
            "types": {
                "folder": {
                    "icon": "fas fa-folder"
                },
                "file": {
                    "icon": "fas fa-file-alt"
                }
            },
            'core': {
                'data': nodes
            }
        });//end jstree

        //open the folder when clicking it (instead of having to target the small arrow)
        $(treeId).on('select_node.jstree', function (e, data) {
            data.instance.toggle_node(data.node);
        });

        $(treeId).on('changed.jstree', function (e, data) {
            //if selected node is not a directory
            if (data.node.original.type > 0) {

                if (data.node.original.type == 1) { //PDF
                    var modal = $('#view-document-modal');
                    modal.find('#filename').html(data.node.original.text);

                    modal.find('#document-iframe').attr('src', data.node.original.link);

                    //console.log(data.node.original.link);

                    modal.modal('show');
                }
                else { //just download it
                    var hiddenLink = $('#non-pdf-link');
                    hiddenLink.attr('href', data.node.original.link);
                    hiddenLink.attr('target', '_blank');
                    hiddenLink[0].click();

                }


            }
        });


        //do search on keyup
        $(searchBoxId).keyup(function () {
            var search = $(this).val();
            $(treeId).jstree('search', search);
        });

        //handle search clear button
        $(searchClearId).click(function () {
            $(searchBoxId).val('');
            $(treeId).jstree('search', '');
        });

    }
}