import { plainToClass } from "class-transformer";
import { DocumentTree } from "../document-tree/document-tree.objects";


export class DocumentLibraryLoader {

    constructor(modelJson: any) {
        $(() => {

            let model: DocumentLibraryModel = plainToClass(DocumentLibraryModel, <DocumentLibraryModel>modelJson);
            model.DocumentSections = plainToClass(DocumentSection, <DocumentSection[]>model.DocumentSections);

            //console.log(model);

            model.DocumentSections.forEach((section: DocumentSection) => {
                new DocumentTree(JSON.parse(section.DocumentsJson), section.TreeId, section.SearchBoxId, section.SearchClearId);                
            });

            // Functionality for section tabs
            $(".section-btn").click(function () {

                if (!$(this).hasClass("active-tab")) {
                    $(".section-btn").removeClass("active-tab");
                    $(this).addClass("active-tab");

                    if ($(this).text() != "All") {
                        let sectionId = "#" + $(this).text().replace(" ", ""); // removes spaces to match html
                        $(".document-section").addClass("inactive-section");
                        $(sectionId).removeClass("inactive-section");
                    }
                    else {
                        $(".document-section").removeClass("inactive-section");
                    }
                }
            })

            // Open + Close Document Repos
            $(".btn-link").click(function () {

                if ($(this).hasClass("active-files")) {
                    $(this).removeClass("active-files");

                    $(this).prev().find(".arrow-down").addClass("inactive-section");
                    $(this).prev().find(".arrow-right").removeClass("inactive-section");
                }

                else {
                    // Deactivate section dropdowns
                    let parentEl = $(this).closest(".accordion-section");
                    parentEl.find(".btn-link").removeClass("active-files");
                    parentEl.find(".arrow-down").addClass("inactive-section");
                    parentEl.find(".arrow-right").removeClass("inactive-section");

                    // Activate this dropdown
                    $(this).addClass("active-files");

                    $(this).prev().children(".arrow-right").addClass("inactive-section");
                    $(this).prev().children(".arrow-down").removeClass("inactive-section");
                }

            });
        
        });
    }
}

export class DocumentLibraryModel {
    Title: string;
    Description: string;
    //Images: string[];
    DocumentSections: DocumentSection[];
}

export class DocumentSection {
    Label: string;
    DocumentsJson: string;
    TreeId: string;
    SearchBoxId: string;
    SearchClearId: string;
}