
//only import what we use.
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
    faBars, faAngleLeft, faAngleRight, faAngleUp, faAngleDown, faTimes, faCaretSquareLeft, faEnvelope,
    faCog, faHome, faUser, faExternalLinkAlt, faUserCircle,
    faFolder, faFileExcel, faFileWord, faFilePowerpoint, faFilePdf, faFile, faFileAlt, faFileAudio, faFileVideo, faFileDownload, faQuestionCircle,
    faCopy, faKey, faExclamation, faFileCsv, faFileImage, faFileCode
} from "@fortawesome/free-solid-svg-icons";

library.add(faBars); library.add(faAngleLeft); library.add(faAngleRight); library.add(faAngleUp); library.add(faAngleDown);
library.add(faTimes); library.add(faCaretSquareLeft); library.add(faEnvelope); library.add(faCog);
library.add(faHome); library.add(faUser); library.add(faExternalLinkAlt); library.add(faUserCircle);
library.add(faFolder); library.add(faFileExcel); library.add(faFileWord); library.add(faFilePowerpoint); library.add(faFilePdf); library.add(faFileAlt); library.add(faFileAudio); library.add(faFileVideo);
library.add(faFile); library.add(faFileDownload); library.add(faQuestionCircle); library.add(faCopy); library.add(faKey); library.add(faExclamation);
library.add(faFileCsv); library.add(faFileImage); library.add(faFileCode);

dom.watch();