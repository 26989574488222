

$(window).on('scroll', function () {

    if ($(this).scrollTop() > 200) {
        //$(".scroll-to-top").fadeIn(400);
        $(".scroll-to-top").addClass("show");

    } else {
        //$(".scroll-to-top").fadeOut(400);
        $(".scroll-to-top").removeClass("show");
    }
});

$(".scroll-to-top").on('click', function (event) {
    event.preventDefault();
    $("html, body").animate({
        scrollTop: 0
    }, 600);
});